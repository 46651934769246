<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.employeeUpdate')"
      :title="$t('messages.employeeUpdate')"
      @submitPressed="submitPressed"
    />
    <employee-form
      v-if="user"
      :userObject="user"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import EmployeeForm from "./components/EmployeeForm.vue";

export default {
  components: {
    TitleBar,
    EmployeeForm,
  },
  data() {
    return {
      user: null,
      action: null,
    };
  },
  created() {
    this.loadEmployee();
  },
  methods: {
    loadEmployee() {
      const id = this.$route.params.id;
      this.$Users.getResource({ id }).then((response) => {
        this.user = response.data;
        if(!this.user.address) {
          this.user.address = {}
        }
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
